<template>
  <Page>
    <TermsAndConditions class="mb-5" />
    <PrivacyPolicy />
  </Page>
</template>

<script>
import PrivacyPolicy from '../legal/PrivacyPolicy.vue'
import TermsAndConditions from '../legal/TermsAndConditions.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    PrivacyPolicy,
    TermsAndConditions
  }
}
</script>
